import React from "react";
import styled from "styled-components";


const Text = styled.p`
  font-size: 16px;
  max-width:400px;
`

const PageInfoText = ({children}) => {
  return (<Text dangerouslySetInnerHTML={{__html: `${children}`}}></Text>)
}


export default PageInfoText