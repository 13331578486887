import styled from "styled-components";

const PageSubtitle = styled.h2`
  margin-left:4vw;
  position: relative;
  pointer-events: auto;
  display: table;
  background-color: white;
  font-size:min(40px,2vw);
  font-weight: 500;
  z-index:300;
  text-transform: uppercase;
  padding:5px 12px 5px 10px;
  margin-top:9px; 
  position:${props => props.show === "true" ? "relative"  : "absolute"};
  top:${props => props.show === "true" ? "0" : "-400"}px;
`

export default PageSubtitle
