import  {useState} from "react";
/* 
  Adds or removes items from Array depending on whether 
  they are already part of the array or not.
*/

export const useToggleStateArrayItems = (initialItems) => {

  const [selectedItems, setSelectedItems] = useState(initialItems)

  const toggleItem = (item) =>{
      if(selectedItems.length > 0){
        let itemTemp  = [...selectedItems];
        if(itemTemp.includes(item)){
          // filter out if it already exists
          itemTemp = itemTemp.filter(itemItem => itemItem !== item); 
        } else {
          //Add item to it if it doesnt exists
          itemTemp.push(item);
        }
        setSelectedItems(itemTemp)
      } else {
        setSelectedItems([item])
      }
  }
    
    return [selectedItems, toggleItem, setSelectedItems];
  };
