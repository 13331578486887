import React, { useContext, useEffect } from "react";
import styled from 'styled-components'
import PixiContext from "../../context/pixiContext";
import { InfoBox } from './infoBox'
import { InfoBoxData } from '../../data/infoBoxesData'
import useWindowDimensions from '../../hooks/useWindowDimensions';

let  viewport
const coordMultiContent = 0.0690
const coordMultiColor = 0.08186

const Wrapper = styled.div`
  top:0px;
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left:0px;
  z-index: -1;
  cursor: move;
`


const initPixi = (PIXI) => {
  const SCREEN_WIDTH  = window.innerWidth
  const SCREEN_HEIGHT = window.innerHeight

  const white = "0xffffff"
  const app = new PIXI.Application(
    {
      height:400,
      width:500,
      backgroundColor: white,
    }
  );

  app.renderer.view.style.position = "absolute";
  app.renderer.view.style.display = "block";
  app.renderer.autoResize = true;
  app.renderer.resize(SCREEN_WIDTH, SCREEN_HEIGHT); 
  return app
}


const initViewport = (app,PIXI,allData,  onCloudScroll) => {
  const Viewport = require("pixi-viewport").Viewport;
  const SCREEN_WIDTH  = window.innerWidth
  const SCREEN_HEIGHT = window.innerHeight
  const WORLD_WIDTH   = window.innerWidth - 100
  const WORLD_HEIGHT  = window.innerHeight - 100
  const colorWheelSize = 69600 // tested values
  const offset = 6150 // got values by tesing
  const middlePoint = colorWheelSize/2 + offset



  // // create viewport
  viewport = new Viewport({
  screenWidth: SCREEN_WIDTH,
  screenHeight: SCREEN_HEIGHT,
  worldWidth: WORLD_WIDTH,
  worldHeight: WORLD_HEIGHT,
  interaction: app.renderer.plugins.interaction, // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
  })

  viewport.setZoom(0.5)
  viewport.moveCenter(middlePoint, middlePoint) // center stuff values picked by trying

  viewport
    .drag()
    .pinch()
    .wheel()
    .decelerate()
    .clampZoom({minScale:0.01,maxScale:0.5})
    .animate({ scale: .01,position: new PIXI.Point(40950, 40950),time: 6000,ease: 'easeInOutSine',})


  viewport.on('drag-start', () => {
  allData.forEach((pattern) => {
    pattern.disableClick = true
  })
  })

  viewport.on('drag-end', () => {
  allData.forEach((pattern) => {
    pattern.disableClick = false
  })  
  })

  viewport.on('wheel', () => {
    onCloudScroll()
  })

  return viewport
}


const placeSprites = (allData, viewport) => {
  allData.forEach( (item,index) => {
    item.x = item.data.posColorWheel.x* coordMultiColor
    item.y = item.data.posColorWheel.y* coordMultiColor
    viewport.addChild(item)
  })
}

const initInfoBoxes = (PIXI,onInfoBoxClick) => {
  return InfoBoxData.map((data) => {
    const posx = (data.type === 'color' ? coordMultiColor : coordMultiContent ) * data.position.x
    const posy = (data.type === 'color' ? coordMultiColor : coordMultiContent) * data.position.y
    return InfoBox({PIXI:PIXI,viewport,posx,posy,width:2000,onClick:onInfoBoxClick,data})
  })
}

const PixiContainer = ({onInfoBoxClick, onCloudScroll}) => {
  const { PIXI, loading, allData, setViewport, setInfoBoxes,  pixiApp, setPixiApp} = useContext(PixiContext);

  useEffect(() => {
    if(loading === false ){
      if(pixiApp === ""){
        /* Initialize pixi and viewport if its the first visit */
        const app = initPixi(PIXI)
        const viewport = initViewport(app,PIXI,allData, onCloudScroll)
        app.stage.addChild(viewport)
        setPixiApp(app)
        setViewport(viewport)
        placeSprites(allData, viewport)
        document.getElementById("canvasContainer")?.appendChild(app.view)
        const infoBoxGraphicsTemp = initInfoBoxes(PIXI,onInfoBoxClick)
        setInfoBoxes(infoBoxGraphicsTemp)
      } else {
        /* If not use old one */
        document.getElementById("canvasContainer")?.appendChild(pixiApp.view)
        
      }
      
      
    }
  },[loading, PIXI])


return (<Wrapper id={"canvasContainer"}></Wrapper>)
}

export default PixiContainer