


const InfoBoxData = [
  { 
    id:"entdecken_augsburgerrot",
    type:"color",
    title:"Augsburger Rot",
    text:"Die Farbe „Türkisch Rot“ eroberte ab der zweiten Hälfte des 18. Jahrhunderts den europäischen Stoffdruck und kam häufig bei „orientalisch“ inspirierten Mustern zum Einsatz. Allerdings blieb die Rezeptur aus dem damaligen Osmanischen Reich lange Zeit ein Geheimnis. In Augsburg gelang es schließlich, ein besonders hochwertiges Rot herzustellen, das sich als „Augsburger Rot“ einen Namen machte.",
    position:{x:428125,y:515625}
  },
  {
    id:"entdecken_anilinfarbstoff",
    type:"color",
    title:"Anilinfarbstoff",
    text:"Die Entdeckung des Anilinfarbstoffs 1856 durch William Henry Perkin stellte eine Revolution für das Färben von Stoffen dar. Der neue Farbstoff, der künstlich hergestellt wurde, erhielt wegen seiner tiefvioletten Färbung die Bezeichnung „Mauvein“ oder auch „Mauve“.",
    position:{x:296875,y:378125}
  },
  {
    id:"entdecken_pinselblau",
    type:"color",
    title:"Pinselblau",
    text:"Nicht jede Farbe ließ sich, historisch betrachtet, allein mit herkömmlicher Druck- oder Färbetechnik auf den Stoff auftragen. Manche Farben wie beispielsweise das „Pinselblau“, auch Schilder- oder Kastenblau genannt, erforderten einen zusätzlichen Arbeitsgang. Sogenannte Schildermädchen malten die Farbe nach dem Druck händisch ein.",
    position:{x:915625,y:409375}
  },
  {
    id:"entdecken_naturfarbstoffe",
    type:"color",
    title:"Naturfarbstoff",
    text:"Bis zur Mitte des 19. Jahrhunderts verwendeten die Färber:innen ausschließlich Naturfarbstoffe, die großenteils aus Pflanzen gewonnen wurden. So erlangten Färberwau, Krapp, Färberwaid, verschiedene Farbhölzer oder Indigo in der Textilfärberei große Bedeutung. Allerdings erwiesen sich die natürlichen Farbstoffe meist als nicht besonders haltbar und blieben auch in der Farbvielfalt begrenzt.",
    position:{x:609375,y:284375}
  },
  {
    id:"entdecken_puce",
    type:"color",
    title:"Flohbraun",
    text:"In der Begriffswelt der historischen Textildruckereien tauchen häufig Farbbezeichnungen auf, die heute nicht mehr geläufig sind. Dazu gehört beispielsweise der Farbton „Puce“, auch „Pues“ oder „Flohbraun“ genannt: ein dunkleres Braun, das manchmal einen violetten oder gelblichen Stich enthält.",
    position:{x:434375,y:790625}
  },
  {
    id:"entdecken_kaminkehrerfond",
    type:"color",
    title:"Kaminkehrerfond",
    text:"Der tiefschwarze Grund, der häufig auf den Stoffen um 1800 zu finden ist, wird auch „Kaminkehrerfond“ genannt. Dieser dunkle Bodendruck lässt die anderen, helleren Farben besonders schön strahlen.",
    position:{x:415625,y:890625}
  },
  {
    id:"entdecken_augsburgersatin",
    type:"content",
    title:"Augsburger Satin",
    text:"Bettwäsche und Vorhänge gehören bis heute zur Grundausstattung vieler Haushalte. Schon für die Neue Augsburger Kattunfabrik (NAK) erwiesen sich diese Stoffe als ein einträgliches Geschäft. Das Unternehmen bot meist mit Blumenmustern bedruckte Baumwollstoffe an, die in Pastelltönen wie Hellblau, Rosa oder Fliederfarben, seltener in Rot gehalten waren. Verkauft wurde der so gestaltete Stoff unter dem Namen „Satin Augusta“, den sich die NAK sogar als Warenzeichen schützen ließ.",
    position:{x:270370,y:388888}
  },
  {
    id:"entdecken_dekomoebelstoff",
    type:"content",
    title:"Dekorations- und Möbelstoff",
    text:"Wie die Musterbücher zeigen, bot die NAK ab 1880 zahlreiche Dekorationsstoffe an, die beispielsweise als Wand- oder Stuhlbespannungen dienten. In diesen Büchern finden sich zwischen 1892 und 1914 vor allem stilisierte Blumen- und Pflanzenmotive, die sich dem Jugendstil zuordnen lassen. In den 1950er Jahren zierten Alltagsmotive die Druckstoffe der NAK. So fanden Abbildungen von Küchenutensilien oder Motive aus den Reiseländern ihren Weg auf Vorhänge und Tischdecken.",
    position:{x:633333,y:492592}
  },
  {
    id:"entdecken_musterbuch",
    type:"content",
    title:"Musterbuch",
    text:"Musterbücher enthalten in der Regel eine Zusammenstellung von Vorlagen oder Arbeitsproben, die vor allem die produzierenden Unternehmen, Manufakturen oder Betriebe selbst oder auch Handelspartner:innen nutzten. Die über 550 Musterbücher aus dem Nachlass der Neuen Augsburger Kattunfabrik (NAK) versammeln etwa 1,3 Millionen Stoffdruckmuster. Daneben finden sich in den ältesten Musterbüchern der NAK auch Musterzeichnungen, Umrissentwürfe und Probedrucke.",
    position:{x:722222,y:796296}
  },
  {
    id:"entdecken_paisley",
    type:"content",
    title:"Paisley-Muster",
    text:"Einhergehend mit der Mode der Kaschmirschals, die im 18.&nbspJahrhundert in Europa aufkam, verbreiteten sich neue Gestaltungs-Motive wie etwa das Pailsey-Motiv. Es ist bis heute beliebt und findet sich in allen erdenklichen Formen auf verschiedensten Stoffen. Seinen Namen erhielt das Motiv durch die schottische Industriestadt Paisley, die sich in der Produktion der erwähnten Schals hervortat. Blumen und Blätter umgeben meist die stilisierte asymmetrische Tropfen- oder Blattform oder füllen sie bisweilen auch aus. Die genaue Herkunft des Motivs ist ungewiss, geht aber vermutlich auf ein persisches Teppichornament zurück.",
    position:{x:588888,y:529629}
  },
  {
    id:"entdecken_calico",
    type:"content",
    title:"Calico",
    text:"Das bedruckte Baumwollgewebe „Calico“ ist nach seinem ursprünglichen Herstellungsort, dem indischen Calicut (Kozhikode) benannt. Traditionelle Weber:innen fertigten dort die einfach gewebten Stoffe aus ungebleichter Baumwolle. Meist gefärbt und bedruckt, gelangten diese Stoffe als Exportware nach Europa, bis die hiesige Industrie schließlich selbst in der Lage war, Gewebe in solcher Qualität herzustellen.",
    position:{x:285185,y:744444}
  },
  {
    id:"entdecken_musterzeichnung",
    type:"content",
    title:"Musterzeichnung",
    text:"Vor dem eigentlichen Druckverfahren steht der zeichnerische Entwurf, der als künstlerische Idee dem Design bzw. dem Dessin zugrunde liegt. In der Textilbranche übernehmen bis heute Dessinateure (Designer:innen) diese Aufgabe. Firmen wie die Neue Augsburger Kattunfabrik (NAK) mussten die künstlerischen Entwürfe in technisch verwertbare Druckmuster umwandeln. Dazu durchliefen die Entwürfe bis in die Zeit nach dem Zweiten Weltkrieg die firmeneigenen Zeichen- bzw. Fotoateliers und Gravurabteilungen.",
    position:{x:551851,y:714814}
  },
  {
    id:"entdecken_dirndlstoff",
    type:"content",
    title:"Dirndlstoff",
    text:"Dirndlstoffe gehörten ab den 1920er Jahren zum häufig wiederkehrenden Repertoire der Neuen Augsburger Kattunfabrik (NAK). Oft weisen sie Herzchen oder figurative Szenen auf, auch Karomuster mit Blumen bildeten ein beliebtes Motiv.",
    position:{x:818518,y:425925}
  }
]


export  {InfoBoxData}