import React, { useContext } from 'react'
import styled from 'styled-components'
import PixiContext from '../../context/pixiContext'
import PageInfoText from '../global/PageInfoText'
import PageInfoTitle from '../global/PageInfoTitle'

const coordMultiContent = 0.0690
const coordMultiColor = 0.08186

const Wrapper = styled.div`
  position: absolute;
  margin-left:4vw; 
  margin-top:20px;
  background-color: white;
  padding: 10px;
  pointer-events: auto;
  width:450px;
`

const Tab = styled.div`
  padding-right:20px;
  color:${props => props.activeTab ? "#000000" : "#C3C3C3" };
  text-transform: uppercase;
  >h3{
    font-weight:500;
  }
`

const TabsWrapper = styled.div`
  display:inline-flex;
  position:relative;
  cursor:pointer;
  &:before{
    content:'';
    position:absolute;
    top:25px;
    left:${props => props.tabIndex === 0 ? "0" : "50" }%;
    width:calc(50% - 15px);
    height:3px;
    background-color: black;
    transition: all 0.3s ease-in-out;
  }
  
`

const ColorContentToggle = (props) => {
  const { allData, viewport, infoBoxes, PIXI, cloudMode, setCloudMode} = useContext(PixiContext);
  const content = [
    `Im Inneren der Musterbücher verbergen sich Tausende von Mustern, die in verschiedensten Farben leuchten. Sortiert in einem Farbkreis, zeigt sich eine faszinierende Bandbreite an Farben und der farbliche Charakter unserer Sammlung.`,
    `Muster entstehen aus der Kombination einzelner Motive, die gleichförmig wiederholt werden. Auf den Stoff aufgebracht, nennt man diese Muster ‚Dessins‘. Wir als Betrachter:innen ordnen die wahrgenommenen Muster für gewöhnlich bestimmten Oberbegriffen zu, wenn etwa Punkte oder Linien überwiegen. Eine besondere Anordnung der Muster ergibt sich, wenn eine Künstliche Intelligenz (KI) sie nach bestimmten Gesichtspunkten analysiert und dahingehend sortiert.`
  ]

  const handleColorClick = () => {
    setCloudMode(0)
    viewport.animate({ scale: .015, position: new PIXI.Point(40950, 40950),time: 2000,ease: 'easeInOutSine',})
    allData.forEach((pattern) => { 
      pattern.moveEased(pattern.data.posColorWheel.x* coordMultiColor,pattern.data.posColorWheel.y* coordMultiColor)
    })
    infoBoxes.forEach((infoBox) => {
      infoBox.toggleType("color")
    })
  }

  const handleContentClick = () => {
    viewport.animate({ scale: .015,position: new PIXI.Point(40950, 40950),time: 2000,ease: 'easeInOutSine',}) // reset zoom to iniatial value of 0.015( see pixi container )
    setCloudMode(1)
    allData.forEach((pattern) => { 
      pattern.moveEased(pattern.data.posAiCloud.x* coordMultiContent,pattern.data.posAiCloud.y* coordMultiContent)
    })
    infoBoxes.forEach((infoBox) => {
      infoBox.toggleType("content")
    })
  }


  return(
    <Wrapper>
      <TabsWrapper tabIndex={cloudMode}>
        <Tab onClick={()=>handleColorClick()} >
          <PageInfoTitle color={cloudMode === 0 ? "#000" : "#C3C3C3" }>Farbe</PageInfoTitle>
        </Tab>
        <Tab onClick={()=>handleContentClick()} activeTab={cloudMode === 1}>
          <PageInfoTitle color={cloudMode === 1 ? "#000" : "#C3C3C3" }>Inhalt</PageInfoTitle>
        </Tab>
      </TabsWrapper>
      <PageInfoText>{content[cloudMode]}</PageInfoText>
    </Wrapper>
  )
}

export default ColorContentToggle