import React, {useContext, useEffect, useState } from 'react'
import PixiContainer from "../components/Pixi/PixiContainer"
import PixiContext from '../context/pixiContext'
import DateRangeSlider from "../components/entdecken/DateRangeSlider"
import CategoryFilterBar from "../components/entdecken/CategoryFilter"
import Seo from '../components/global/Seo'
import PageSubtitle from "../components/global/PageSubtitle"
import styled from 'styled-components'
import { useToggleStateArrayItems } from '../hooks/useToggleStateArrayItems'
import categories from '../data/categories'
import ColorContentToggle from '../components/entdecken/ColorContentToggle'
import useKioskMode from "../hooks/useKioskMode"

const FilterWrapper = styled.div`
  z-index:999;
  box-sizing: border-box;
  bottom:${props => props.kioskMode ? "70px" : "10px"};
  position:absolute;
  width:83%;
  height: 120px;
  padding:20px;
  background-color: #fff;
  right:200px;
  @media (max-width: 1500px) {
    width:80%;
  }
`

const InfoBoxWrapper = styled.div`
  background-color: #fff;
  bottom:${props => props.kioskMode ? "calc(15% + 60px)" : "15%"};
  padding: 10px;
  position:absolute;
  left:4%;
  width:450px;
  >h2{
    font-family: 'Avenir Next', sans-serif;
    font-size: 16px;
    line-height:26px;
    font-weight: bold;
  }
  >p{
    font-family: 'Avenir Next', sans-serif;
    font-size: 16px;
    line-height:26px;
  }
`

const CloseButtonWrapper = styled.div`
  z-index:100;
  cursor:pointer;
  position:absolute;
  background-color:white;
  top:10px;
  right:10px;
  width:25px;
  height:25px;
  display:grid;
  place-items: center;
  font-size: 30px;
  >svg{
    width: 100%;
    height: 100%;
    stroke:black;
    stroke-width:2;
    &:hover{
      stroke-width:2.5;
      transform: scale(1.1);
    }
  }
`

const InfoBox = styled.div`
  z-index:9999;
  position:absolute;
  right:15%;
  top:20%;
  max-width:300px;

  >p{
    font-size:16px;
    font-weight: 600;
    padding: 10px 20px;
    background-color: #fff;
    text-transform: uppercase;
  }
  
`


const Entdecken= () => {
  const { allData, infoBoxes, showTooltip, setShowTooltip, infoBoxContent, setInfoBoxContent } = useContext(PixiContext);
  const [selectedDateRange, setSelectedDateRange] = useState([1783, 1997]);
  const [visiblePatternsLength, setVisiblePatternsLength] = useState(allData.length)
  const [selectedCategories, toggleCategory, setSelectedCategories] = useToggleStateArrayItems(categories.map(category => category.filterName))
  const [kioskMode, setKioskMode] = useKioskMode();

  function resetFilter(){
    setSelectedDateRange([1783, 1997])
    setSelectedCategories(categories.map(category => category.filterName))
  }

  function resetAllPatterns(){
    allData.forEach((pattern) => { pattern.alpha = 1})
  }
  
  useEffect(() => {
    const resetPatternsOnEntry = () => allData.forEach(pattern => pattern.alpha = 1) 
    setVisiblePatternsLength(allData.length) // refresh numebr on entry
    resetPatternsOnEntry()
  },[allData])

  useEffect(() => {
    const filterByCategory = (selectedCategories) => {
      if(selectedCategories.length !== categories.length) { // if all catgories are selected dont filter => also show patterns with catgory:null
        const dataToHide = allData.filter((pattern) => !pattern.data.category.some(category => selectedCategories.includes(category)))
        dataToHide.forEach((pattern) => { pattern.alpha = 0.1})
      }
    }
    const filterByDate = (dateRange) => {
      const lowerDate = dateRange[0]
      const higherDate = dateRange[1]
      const dataToHide = allData.filter((pattern) => pattern.data.date.from < lowerDate || pattern.data.date.to > higherDate )
      dataToHide.forEach((pattern) => { pattern.alpha = 0.1})
    }
    const filtersAtDefault = (selectedDateRange[0] === 1783 && selectedDateRange[1] === 1997) && selectedCategories.length == categories.length
    if(!filtersAtDefault){
      resetAllPatterns()
      setInfoBoxContent("")
      filterByDate(selectedDateRange)
      filterByCategory(selectedCategories)
      const visiblePatterns = allData.filter(pattern => pattern.alpha === 1) 
      setVisiblePatternsLength(visiblePatterns.length)
    }
  },[selectedCategories, selectedDateRange, allData])

  
  useEffect(() => {
    const handleInfoBoxClick = () =>{
      const clickedAlreadyActive = infoBoxContent == ""
      resetFilter()
      resetAllPatterns()
      if(clickedAlreadyActive){ // content will be empty string if clicked at the same one
        const activeBox = infoBoxes.find(box => box.selected)
        activeBox?.reset(); 
        allData.forEach(pattern => pattern.alpha = 1) 
      } else {
        const dataToHide = allData.filter((pattern) => !pattern.data.infobox.includes(infoBoxContent?.id))
        const boxesToReset = infoBoxes.filter(box => box.data.id !== infoBoxContent?.id)
        boxesToReset.forEach(box => box.reset())
        dataToHide.forEach((pattern) => { pattern.alpha = 0.1})
      }
    }
    handleInfoBoxClick()
    console.log('click at info box')
  },[infoBoxContent, infoBoxes, allData])




  const handleCategorySelect = (selectedCategory) => {
    if(selectedCategories.length === categories.length) { 
      // if all catgories are selected select just the one clicked at
      setSelectedCategories([selectedCategory])
    } else {
      toggleCategory(selectedCategory)
    }
  }
 
  return (
    <>
      <Seo/>
      {showTooltip && <InfoBox showTooltip={showTooltip}><p>ZOOME MIT DEM SCROLLRAD IM FARBKREIS</p></InfoBox>}
      <PageSubtitle show="true">{`Erkunde ${visiblePatternsLength.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} Muster aus unserer Sammlung`}</PageSubtitle>
      <ColorContentToggle></ColorContentToggle>
      <FilterWrapper kioskMode={kioskMode}>
        <CategoryFilterBar onCategoryChange={(category) => handleCategorySelect(category)} categories={categories} selectedCategories={selectedCategories}/>
        <DateRangeSlider onRangeValueChange={((values) => setSelectedDateRange(values))} values={selectedDateRange}/>
      </FilterWrapper >
      <PixiContainer 
        onCloudScroll={() => setShowTooltip(false)}
        onInfoBoxClick={(InfoBox) => setInfoBoxContent((prevstate) => InfoBox.data !== prevstate ? InfoBox.data : "")}
      />
      {infoBoxContent !== "" &&
        <InfoBoxWrapper>
          <CloseButtonWrapper onClick={() => setInfoBoxContent("")}>  
            <svg viewBox="0 0 40 40">
              <path  d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </svg>
          </CloseButtonWrapper>
          <h2>{infoBoxContent?.title}</h2>
          <p dangerouslySetInnerHTML={{__html: infoBoxContent?.text}}/> 
        </InfoBoxWrapper>
      }
    </>
  )
}

export default Entdecken

