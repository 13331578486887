import React from "react";
import styled from "styled-components";


const Title = styled.h3`
  text-transform: uppercase;
  color:${props => props.color ? props.color : "#B3B3B3"};
  font-size:min(1.25vw,24px);
  font-weight: 500;
  padding-bottom:10px;
`

const PageInfoTitle = ({children, color}) => {
  return (<Title color={color}>{children}</Title>)
}


export default PageInfoTitle