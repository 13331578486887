import React from 'react';
import { Range, getTrackBackground } from 'react-range';
import styled from "styled-components"

const Thumb = styled.div`
  height:15px;
  width:15px;
  border-radius:50%;
  border: 1.5px solid #000;
  background-color: #fff;
`

const DateMarkerWrapper = styled.div`
  display:flex;
  justify-content: space-between;
  position: absolute;
  top:-20px;
  width: calc(100% + 35px);
  left: -20px;
`

const DateMarker = styled.div`

`

const RulerLines = styled.div`
  height:5px;
  width: 0.5px;
  background-color: #000;
`

const RulerLinesWrapper = styled.div`
  display:flex;
  justify-content: space-between;
  padding-top:2.5px;
`

const MarkingsWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  height:5px;
`

const Wrapper = styled.div`
  margin-top: 40px;
  user-select: none;
  padding:0 30px;
`

const DateRangeSlider = ({onRangeValueChange ,values}) => {
  const dateMarkers = [1780,1800,1820,1840,1860,1880,1900,1920,1940,1960,1980,2000]
  const MIN = Math.min(...dateMarkers)
  const MAX = Math.max(...dateMarkers)
  
  return (
    <Wrapper>
      <Range
        draggableTrack={false}
        values={values}
        step={1}
        min={MIN}
        max={MAX}
        rtl={false}
        onChange={(values) => {
          onRangeValueChange(values)
        }}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{cursor:"move"}}
          >
          <MarkingsWrapper>
            <DateMarkerWrapper> 
            {dateMarkers.map(date => (
              <DateMarker>{date}</DateMarker>
            ))}
            </DateMarkerWrapper>
            <RulerLinesWrapper>
            {[...Array(150)].map(x=>(
              <RulerLines/>
            ))}
            </RulerLinesWrapper>
          </MarkingsWrapper>
            <div
              ref={props.ref}
              style={{
                userSelect: "none",
                height: '3.5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values,
                  colors: ['#ccc', '#000', '#ccc'],
                  min: MIN,
                  max: MAX,
                  rtl:false
                }),
                alignSelf: 'center'
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <Thumb {...props} style={{...props.style}}/>
        )}
      />
      <output id="output">
  
      </output>
    </Wrapper>
  );
};

export default DateRangeSlider;