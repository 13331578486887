import React from 'react'
import styled from 'styled-components'


const Wrapper = styled.div`
  display:flex;
  width:100%;
  justify-content:space-between;
  box-sizing: border-box;
  user-select: none;
`

const Category = styled.div`
  border:1.5px solid ${props => props.selected ? "black": "transparent"};
  padding:3px;
  font-size:calc(8px + 0.2vw);
  text-transform:uppercase;
  letter-spacing: 2.5px;
  box-sizing: border-box;
  user-select: none;
  @media (max-width: 1500px) {
    font-size:calc(6px + 0.2vw);
  }
`



const CategoryFilterBar = ({onCategoryChange, selectedCategories, categories}) => {

  return (
    <Wrapper>
      {categories.map((category) => (
        <Category 
          onClick={()=>onCategoryChange(category.filterName)} 
          selected={selectedCategories.includes(category.filterName)} 
          key={category.filterName}
        >
          {category.displayName}
        </Category>
      ))}
    </Wrapper>
  )
}

export default CategoryFilterBar