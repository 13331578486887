import { timer as d3_timer } from 'd3-timer';
import { easePolyInOut as d3_easePolyInOut } from 'd3-ease';

export const InfoBox = ({PIXI, viewport,posx,posy,width,onClick, data}) => {

  const height = width
  const offset = 300
  const textStyle= new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: height/1.3,
    fontStyle: 'italic',
    fontWeight: 'bold',
    fill: '#000',
    lineJoin: 'round',
  });



  const basicText = new PIXI.Text('?', textStyle);
  basicText.x = posx+height/4+offset;
  basicText.y = posy+height/10+offset;

  const infoBoxElem = new PIXI.Graphics();
  infoBoxElem.data = {...data}
  infoBoxElem.selected = false;
  // show only color info boxes initially 
  if(data.type === "content"){
    infoBoxElem.alpha = 0
  }

  infoBoxElem.lineStyle(100, 0x000, 1);
  infoBoxElem.beginFill(0xFFFFFFFF);
  infoBoxElem.drawRect(posx+offset, posy+offset, width, height);
  
  infoBoxElem.interactive = true;
  infoBoxElem.buttonMode = true;

  infoBoxElem.defaultCursor = 'pointer';
  infoBoxElem.endFill();
  infoBoxElem.click = (e) => {
    onClick(infoBoxElem)
    if(infoBoxElem.selected){
      infoBoxElem.selected = false;
      infoBoxElem.tint = 0xFFFFFF
      textStyle.fill = 0x000
      basicText.text = "?"
      basicText.x = posx+height/4+offset;
      basicText.y = posy+height/10+offset;
    } else {
      infoBoxElem.selected = true;
      infoBoxElem.tint = 0x000
      textStyle.fill = 0xFFFFFF
      basicText.x = posx + height/3+offset;
      basicText.y = posy + height/10+offset;
      basicText.text = "!"
    }
  }

  infoBoxElem.reset = () => { 
    infoBoxElem.selected = false;
    infoBoxElem.tint = 0xFFFFFF
    textStyle.fill = 0x000
    basicText.text = "?"
    basicText.x = posx+height/4+offset;
    basicText.y = posy+height/10+offset;
  }

  infoBoxElem.toggleType = (type) => {
    /* transition animation when chaning color/content toggle */
    const durationFadeIn = 2000
    const delayFadeIn = 1
    const durationFadeOut = 500
    const delayFadeOut = 0
    if(infoBoxElem.data.type === type ){
      let timerFadeIn = d3_timer(function(elapsed) {
        let t = elapsed / durationFadeIn;
        infoBoxElem.alpha =  d3_easePolyInOut(t, 3);
        if(elapsed > durationFadeIn) {
          timerFadeIn.stop();
        }
      }, delayFadeIn);
    } else {
      let timerFadeOut = d3_timer(function(elapsed) {
        let t = elapsed / durationFadeOut;
        infoBoxElem.alpha =  1- d3_easePolyInOut(t, 3);
        if(elapsed > durationFadeOut) {
          timerFadeOut.stop();
        }
      }, delayFadeOut);
    }
  }


  infoBoxElem.addChild(basicText)
  viewport.addChild(infoBoxElem)

  return infoBoxElem
}
