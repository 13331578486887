const categories = [
  {displayName:"Folklore",filterName:"folklore"},
  {displayName:"Linien und Streifen",filterName:"line_stripe"},
  {displayName:"Farbverläufe",filterName:"gradient"},
  {displayName:"Pflanzen",filterName:"plant"},
  {displayName:"Bänderungen",filterName:"banding"},
  {displayName:"Punkte",filterName:"dot"},
  {displayName:"Figurativ",filterName:"figurative"},
  {displayName:"Paisley",filterName:"paisley"},
  {displayName:"Karo",filterName:"checked"},
  {displayName:"Effekte",filterName:"effekte"},
  {displayName:"Grafik",filterName:"graphic"},
  {displayName:"Blumen",filterName:"flower"},
]

export default categories